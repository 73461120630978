import axios from 'axios';
import { stringify } from 'qs';
import { sendLogs } from './sendLogs';

// axios.defaults.baseURL = process.env.BACKEND_URL; old stuff
axios.defaults.baseURL = window?.properties?.BACKEND_URL || process.env.BACKEND_URL;
// axios.defaults.baseURL = '/mock';
axios.defaults.withCredentials = true;
axios.defaults.paramsSerializer = (params) => stringify(params, { arrayFormat: 'comma' });

axios.interceptors.request.use((config) => {
  ApiClient.onRequest();
  sendLogs({
    '': 'REQUEST',
    method: config.method.toUpperCase(),
    url: config.baseURL + config.url.split('?')[0],
  });
  return config;
});

const getSuccessObject = (response) => ({ data: response.data, status: response.status, headers: response.headers });

const sendLogsByType = (type, response) => {
  let logConfig = {
    method: response.config.method.toUpperCase(),
    url: response.config.baseURL + response.config.url.split('?')[0],
    status: response.status,
  };

  if (type === 'error') {
    logConfig = {
      ...logConfig,
      ...(typeof response.data === 'string' ? { data: 'empty response' } : response.data),
    };
  }

  sendLogs(logConfig, type === 'error');
};

axios.interceptors.response.use(
  (response) => {
    ApiClient.onResponse();

    if (typeof response.data.success === 'undefined') {
      sendLogsByType('success', response);
      return getSuccessObject(response);
    }
    if (!response.data.success) {
      if (!response.data.error) {
        sendLogsByType('success', response);
        return getSuccessObject(response);
      }

      const code = response.data.error?.code;

      if (code === 401 || code === 1010) {
        sendLogsByType('error', response);
        ApiClient.onUnauthorized();
      }

      if (Math.trunc((code ?? 0) / 100) === 5 || code === -1) {
        sendLogsByType('error', response);
        ApiClient.onError(response.data.error);
      }

      return Promise.reject(response.data.error);
    }

    sendLogsByType('success', response);

    return response.data.result ?
      { data: response.data.result, status: response.status } :
      getSuccessObject(response);
  },
  (error) => {
    ApiClient.onResponse();

    if (typeof error.code !== 'undefined') {
      // api error
    } else {
      // http error
      ApiClient.onError(error);
      sendLogsByType('error', error.response);
    }

    const errorToSend = { ...(error?.response?.data ?? {}), status: error.response?.status ?? -1 };
    return Promise.reject(errorToSend);
  },
);

/**
 * @typedef {Object} ApiError
 */
/**
 * @typedef {Object} PaginationParam
 */
/**
 * @typedef {Object} SortParam
 * @property {string} sort
 * @property {string} order
 */
/**
 * @typedef {Object} MoneyAmount
 * @property {number} amount
 * @property {number} currency
 * @property {number} exp
 */

export const ApiClient = {
  onUnauthorized: () => {},
  onError: () => {},
  onRequest: () => {},
  onResponse: () => {},

  get baseURL() {
    return axios.defaults.baseURL;
  },

  utils: {
    agreement() {
      return axios({
        method: 'get',
        url: '/user/acceptCondition',
      });
    },
    requestForNewSms() {
      return axios({
        method: 'post',
        url: '/user/acceptCondition',
      });
    },
  },
  user: {

    loginViaToken(data) {
      return axios({
        method: 'post',
        url: '/user/tokenLogin',
        data: axios.defaults.paramsSerializer(data),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
    },

    loginViaAgreementConfirmation(smsCode) {
      return axios({
        method: 'get',
        url: `/user/checkFactor?confirmationCode=${smsCode}`,
        headers: { 'x-api-version': '2.0.0' },
      });
    },
    // /user/checkFactor
  },

  orderDocs: {
    getDocTypes(params) {
      return axios.get('/document/documentType', { params });
    },
    getDocWayOfGetting(requestTypeShortName) {
      return axios.get(`/document/wayOfGetting?requestTypeShortName=${requestTypeShortName}`);
    },
    getDocAddressType() {
      return axios.get('/document/addressType');
    },
    sendDocRequest(data) {
      return axios({
        method: 'POST',
        url: '/document/request',
        data: axios.defaults.paramsSerializer(data),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
    },
  },

  bank: {
    getIncomeAccount() {
      return axios.get('/bank/bankIncomeAccount');
    },
    getBanks() {
      return axios.get('/bank/bic');
    },
  },

  contracts: {

    getContracts() {
      return axios({
        method: 'GET',
        url: '/contract/contract',
      });
    },
    getContractDetails(contractNumber) {
      return axios({
        method: 'GET',
        url: `/contract/details?contractNumber=${contractNumber}`,
      });
    },
    getCardDetails(contractNumber, operatorId) {
      return axios({
        method: 'GET',
        url: `/card/details?contractNumber=${contractNumber}&operatorId=${operatorId}`,
        headers: { 'x-api-version': '2.0.0' },
      });
    },
    getContractPaymentSchedule(contractGid, operatorId) {
      return axios({
        method: 'GET',
        url: `/contract/schedule?contractGid=${contractGid}&operatorId=${operatorId}`,
      });
    },
    getContractRepaymentHistory(params) {
      return axios.get('/contract/repayment', { params });
    },
    getContractOperationLogAppeals(params) {
      return axios.get('/user/request', { params });
    },
    getContractCardOperations(params) {
      return axios.get('/card/operation', { params });
    },
    getContractRequisites(contractNumber, operatorId) {
      return axios({
        method: 'GET',
        url: `/contract/bankDetails?contractNumber=${contractNumber}&operatorId=${operatorId}`,
      });
    },
    getContractRemittanceAmount(params) {
      return axios.get('/contract/remittanceAmount', { params });
    },
    getContractRemittanceTypes(params) {
      return axios.get('/contract/remittanceType', { params });
    },
    getContractRemittanceAccounts(params) {
      return axios.get('/contract/remittanceAccountNumber', { params });
    },
    sendContractRemittanceRequest(params) {
      return axios({
        method: 'POST',
        url: '/contract/remittance',
        data: axios.defaults.paramsSerializer(params),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
    },
    confirmContractRemittanceRequest(params) {
      return axios.get('/contract/checkFactor', { params });
    },
    getContractRequisitesDocUrl(contractNumber, operatorId) {
      return axios({
        method: 'POST',
        url: '/contract/bankDetails',
        data: axios.defaults.paramsSerializer({ contractNumber, operatorId }),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
    },
    getEarlyPartialRepaymentCalc(params) {
      return axios.get('/contract/perRequest', { params });
    },
    getEarlyFullRepaymentCalc(contractNumber, operatorId, ferDate) {
      const params = { contractNumber, operatorId };
      if (ferDate) {
        params.ferDate = ferDate;
      }
      return axios.get('/contract/ferRequest', { params });
    },
    sendEarlyPartialRepaymentRequest(data) {
      return axios({
        method: 'POST',
        url: '/contract/perRequest',
        data: axios.defaults.paramsSerializer(data),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
    },
    sendEarlyFullRepaymentRequest(data) {
      return axios({
        method: 'POST',
        url: '/contract/ferRequest',
        data: axios.defaults.paramsSerializer(data),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
    },
    getFerAvailability(contractNumber) {
      return axios.post('/contract/ferAvailability',
        null,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, params: { contractNumber } });
    },
    getServiceFinProtect(contractNumber) {
      return axios.get('contract/service/finProtect', { params: { contractNumber } });
    },
    getServiceFinProtectActAvail(contractNumber, serviceId) {
      return axios.get('contract/service/finProtectActAvail', { params: { contractNumber, serviceId } });
    },
    sendFinProtectRefuse(params) {
      return axios.post('contract/service/finProtectRefuse', params);
    },
    sendPrePaymentSchedule(params) {
      return axios.post('contract/service/prePaymentSchedule', params);
    },
    finProtectActivation(params) {
      const searchParams = new URLSearchParams();
      Object.keys(params).forEach((key) => searchParams.append(key, params[key]));
      return axios.post('contract/service/finProtectActivation', searchParams);
    },
    getEarlyRepaySumByDate(params) {
      return axios.get('contract/overdue', { params });
    },
    getNotSignedSupAgreement(params) {
      return axios.get('contract/service/notSignedSupAgreement', { params });
    },
    getSupAgreement(params) {
      return axios.get('contract/service/supAgreement', { params });
    },
    sendSupAgreement(params) {
      return axios.post('contract/service/supAgreement', null, { params });
    },
    finProtectRegistration(params) {
      return axios.put('contract/service/finProtectRegistration', axios.defaults.paramsSerializer(params));
    },
    getCheckFactor(params) {
      return axios.get('contract/checkFactor', { params });
    },
    sendCheckFactor(params) {
      return axios.post('contract/checkFactor', params);
    },
    getConfirmCode(creditId) {
      return axios.get(`internal/confirmcode?login=${creditId}&confirmTypeCode=CONFIRM_SUP_AGRMNT`); //TODO only for test
    },
    calcNextPaymentDate(params) {
      return axios.get('contract/service/calcNextPaymentDate', { params });
    },
  },

  auth: {
    /**
     * @param {Object} data
     * @param {string} data.username
     * @param {string} data.password
     * @param {string} data.type
     * @returns {Promise<any>}
     */
    login(data) {
      // return HttpClient.post('/auth/login', data);
      return axios.post('/auth/login', data);
    },

    /**
     * @returns {Promise<any>}
     */
    logout() {
      return axios.post('/auth/logout');
    },

    /**
     * @param {string} login
     * @returns {Promise<any>}
     */
    remember(login) {
      // return axios.post('/auth/remember', { login });
      return axios.post('/auth/login', { login });
    },

    /**
     * TODO: confirm
     * @param {any} data
     * @returns {Promise<any>}
     */
    confirm(data) {
      return axios.post('/auth/confirm', data);
    },

    /**
     * @param {Object} data
     * @param {string} data.country
     * @param {string} data.inn
     * @param {string} data.email
     * @param {string} data.password
     * @returns {Promise<any>}
     */
    registration(data) {
      return axios.post('/auth/registration', data);
    },
  },
  insurancesProducts: {
    getUploadDocumentTypes(params) {
      return axios.get('/document/uploadedType', { params });
    },
    cascoProlongation(params) {
      return axios.post('/document/cascoProlongation', params);
    },
    getInsurancesProducts(params) {
      return axios.get('/contract/insurances', { params });
    },
    sendInsuranceProlongation(data) {
      return axios.post('/document/insuranceProlongation', data);
    },
    getCarInfo(params) {
      return axios.get('/contract/carInfo', { params });
    },
    sendCarInfo(data) {
      return axios.post('/document/carInfo', data);
    },
    getHeadCompany() {
      return axios.get('/insurance/headCompany');
    },
  },
  rateHistory: {
    getChangeRateHistoryList(params) {
      return axios.get('/contract/rateHistory/changeRateHistoryList', { params });
    },
    getChangeRateConditionList(params) {
      return axios.get('/contract/rateHistory/changeRateConditionList', { params });
    },
  },
  services: {
    getNotSignedSupAgreement(params) {
      return axios.get('contract/service/notSignedSupAgreement', { params });
    },
    getServiceFinProtect(params) {
      return axios.get('contract/service/finProtect', { params });
    },
    getServiceFinProtectActAvail(params) {
      return axios.get('contract/service/finProtectActAvail', { params });
    },
    finProtectRegistration(params) {
      const searchParams = new URLSearchParams(params); //formData
      return axios.put('contract/service/finProtectRegistration', searchParams);
    },
    getSupAgreement(params) {
      return axios.get('contract/service/supAgreement', { params });
    },
    sendSupAgreement(params) {
      return axios.post('contract/service/supAgreement', null, { params });
    },
    getCheckFactor(params) {
      return axios.get('contract/checkFactor', { params });
    },
    sendCheckFactor(data) {
      return axios.post('contract/checkFactor', data);
    },
    calcNextPaymentDate(params) {
      return axios.get('contract/service/calcNextPaymentDate', { params });
    },
    sendPrePaymentSchedule(data) {
      return axios.post('contract/service/prePaymentSchedule', data);
    },
    finProtectActivation(params) {
      const searchParams = new URLSearchParams(params); //formData
      return axios.post('contract/service/finProtectActivation', searchParams);
    },
    sendServiceFinProtectRefuse(params) {
      return axios.post('contract/service/finProtectRefuse', params);
    },
    getConfirmCode(login) {
      return axios.get(`internal/confirmcode?login=${login}&confirmTypeCode=CONFIRM_SUP_AGRMNT`); //only for test
    },
  },
};
